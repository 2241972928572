import { objectWithFallback } from 'utils/object';

export const PartnerLogos = objectWithFallback({
  11: 'https://storage.googleapis.com/white-labelled-marketplace-assets/jackrabbit/logo.png',
  22: 'https://storage.googleapis.com/white-labelled-marketplace-assets/ezstreet/logo.png',
  32: 'https://storage.googleapis.com/white-labelled-marketplace-assets/pnw/smLogo.png',
  42: 'https://storage.googleapis.com/white-labelled-marketplace-assets/simplydelivery/logo.png',
  43: 'https://storage.googleapis.com/white-labelled-marketplace-assets/deliverondemand/logo.png',
  44: 'https://storage.googleapis.com/white-labelled-marketplace-assets/bulldawg/logo.png',
  45: 'https://storage.googleapis.com/white-labelled-marketplace-assets/coastaleats/logo.png',
  46: 'https://storage.googleapis.com/white-labelled-marketplace-assets/salecalle/logo.png',
  57: 'https://storage.googleapis.com/white-labelled-marketplace-assets/savtakeout/smLogo3.png',
  60: 'https://storage.googleapis.com/white-labelled-marketplace-assets/munchiedude/logo.png',
  63: 'https://storage.googleapis.com/white-labelled-marketplace-assets/ezdeliver/logo.png',
  108: 'https://storage.googleapis.com/white-labelled-marketplace-assets/vinason/small_logo.png',
  122: 'https://storage.googleapis.com/white-labelled-marketplace-assets/vivachicken/small_logo.png',
  127: 'https://storage.googleapis.com/white-labelled-marketplace-assets/pig%26pintcatering/small_logo.png',
  131: 'https://storage.googleapis.com/white-labelled-marketplace-assets/disbegood/small_logo.png',
  133: 'https://storage.googleapis.com/white-labelled-marketplace-assets/planetsub/small_logo.png',
  134: 'https://storage.googleapis.com/white-labelled-marketplace-assets/newcateringconnections/small_logo.png',
  144: 'https://storage.googleapis.com/white-labelled-marketplace-assets/bredhotchicken/small_logo.png',
}, null);
